import * as React from "react"


// markup
const NotFoundPage = () => {
  return (
      <section className="hero-404 flex-column decor-box d-flex align-items-center justify-content-center" style={{backgroundImage: `url(<?php echo $image_hero; ?>);`}}>
        <div className="container">
          <div className="d-flex justify-content-center align-items-center">
            <div className="box-description text-center">
              <h1>404</h1>
              <div className="description">Page Not Found</div>
            </div>
          </div>
        </div>
      </section>
  )
}

export default NotFoundPage
